  .home__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    word-wrap: break-word;
   }
   
   body {
    background-image: url('/public/bg.jpg');
  }
  .home__btn {
    padding: 10px;
    font-size: 18px;
    margin-top: 10px;
    border-radius: 10px;
    border: none;
    color: white;
    background-color: black;
    word-wrap: break-word;
  }
  
  .nav-link.active {
    text-align: center;
    color: lightgray !important;
    /* text-decoration: underline; */
    font-weight: bold;
  }

  .navbar-inner {
    background:transparent;
  }

  .navbar {
    position: relative;
    z-index: 1000;
  }

  .navbar-collapse {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    padding: 10px;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
  }

  @media (min-width: 992px) {
    .navbar-collapse {
      position: static;
      background: transparent;
      padding: 0;
    }
  }
